import http from "./http";
import { Request } from "../app/requests/models/request";
import { Module } from "../app/modules/models/module";
import { Service } from "../app/services/models/service";
import { ServiceCategory } from "../app/services/models/service_category";
import { RequestFile } from "../app/requests/models/request_file";
import axios from "axios";
import { Payment } from "../app/payments/models/payment";
import { PaymentAdvice } from "../app/payments/models/payment_advice";

class ApiService {

    async getModule(id:any) {
        var result = await http.get(`/modules/${id}/`);
        return new Module(result.data);
    }

    async getModules(category:any) {
        //var result = await http.get(`/modules/${category}`);
        var result = await http.get(`/modules/`);
        return result.data;
    }


    async getService(slug:any) {
      var result = await http.get(`/services/${slug}/`);
      return new Service(result.data);
    }
    async getServices() {
      var result = await http.get(`/services/`);
      return result.data.map((data:any) => new Service(data));
    }
    async getServiceCategory(slug:any) {
      var result = await http.get(`/service_categories/${slug}/`);
      return new ServiceCategory(result.data);
    }
    async getServiceCategories() {
      var result = await http.get(`/service_categories/`);
      return result.data.map((data:any) => new ServiceCategory(data));
    }

    async getRequest(id:any) {
        var result = await http.get(`/requests/${id}/`);
        return new Request(result.data);
    }
    async getRequests(category_slug:any, service_id:any, module_id:any, page:number) {
        var serviceQuery = service_id ? `service=${service_id}&` : "";
        var moduleQuery = module_id ? `module=${module_id}&` : "";
        var result = await http.get(`/requests/?${serviceQuery}${moduleQuery}page=${page}`);
        return result.data.map((data:any) => new Request(data));
    }
    async getMyRequests(category_slug:any, page:number) {
        var result = await http.get(`/requests/?page=${page}&filter=mine`);
        return result.data.map((data:any) => new Request(data));
    }
    async getWaitingProtocolRequests(category_slug:any, page:number) {
        var result = await http.get(`/requests/?page=${page}&filter=waiting_protocol`);
        return result.data.map((data:any) => new Request(data));
    }
    async saveRequest(status:String|null, module:Module, request?:Request) {
      if(request != null){
        var result = await http.put(`/requests/${request.id}/`, {
          module: module.id,
          values: JSON.stringify(module.values),
          status: status,
        });
      }else{
        var result = await http.post('/requests/', {
          module: module.id,
          values: JSON.stringify(module.values ?? {}),
          status: status,
        });
      }
      return result.data;
    }
    async sendRequestToProtocol(request:Request) {
      var result = await http.post(`/requests/${request.id}/send_to_protocol/`, {});
      return new Request(result.data);
    }
    async saveRequestProtocolId(request:Request, protocolId:string) {
      var result = await http.post(`/requests/${request.id}/set_protocol_id/`, {
        protocol_id: protocolId
      });
      return new Request(result.data);
    }
    async uploadTempFile(requestFile:RequestFile) {
      const formData = new FormData();

      //formData.append("filename", fileData.file.name);

      try{
        formData.append("file", requestFile.file!);

        const result = await http.put(
          `/files/upload/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Content-Disposition': 'inline;filename=' + requestFile.file!.name,
            }, 
            withCredentials: true
          }
        );
        
        return new RequestFile(result.data);

      }catch(e){ 
        requestFile.status = "error";
      }

      return requestFile;
    }
    async getRequestFileData(id:any) {
      var result = await http.get(`/files/${id}/`);
      return new RequestFile(result.data);
    }

    async setRequestStatus(request:Request, status:string, notify:boolean, note?:string){
      const result = await http.post('/request_status/', {
        request: request.id,
        status: status,
        notify_user: notify,
        note: note
      });

      return result.data;
    }

    async checkPaymentAdvice(iuv:any) {
      try{
        let result = await http.get(`/payments/check/${iuv}/`);

        if(result.status === 200){
          return true;
        }
        return false;

      }catch(e){
          return false;
      }
    }

    async getPayment(id:any) {
      var result = await http.get(`/payments/${id}/`);
      return new Payment(result.data);
    }
    async getPaymentsByRequestId(requestId:string) {
        var result = await http.get(`/payments/?request=${requestId}`);
        return result.data.map((data:any) => new Payment(data));
    }
    async getPayments(request?:Request) {
        var result = await http.get(`/payments/?request=${request?.id}`);
        return result.data.map((data:any) => new Payment(data));
    }
    async createPayment(request:Request, amount:number, reason:String, service:number, ref:String, iuv?:String, note?:String, notify?:boolean) {
      const result = await http.post('/payments/', {
        request: request.id,
        iuv: iuv,
        amount: amount,
        serviceId: service,
        refType: ref,
        reason: reason,
        note: note ?? "",
        notify_user: notify,
      });

      return result.data;
    }
    async initPayment(payment:Payment) {
      const result = await http.get(`/payments/init/${payment.id}`);

      return result.data;
    }
    async simulatePayment(payment:Payment) {
      const result = await http.put(`/payments/simulate/${payment.id}/`,{
        iuv: payment.iuv
      });

      return result.data;
    }
    async simulateAllPayment(requestId:string) {
      const result = await http.put(`/payments/simulate/all/${requestId}/`,{});

      return result.data;
    }

    async startPayment() {
      /*
      const resutl = await axios.get(
        "https://op.spid.agid.gov.it/userinfo",
        {
          headers: { Authorization: "Bearer qawj3o27wf0tlopgqznlhg00gf37pvki" },
        }
      )
      */
      await axios.get(
        "http://192.168.222.8:8081/govpay/backend/api/backoffice/rs/basic/v1/pagamenti",
        /*
        {
            "pendenze": [
                {
                    "idA2A": "sp_digitale",
                    "idPendenza": "1"
                }
            ],
            "urlRitorno": "https://frontend.actainfo.it",
            "soggettoVersante": {
                "tipo": "F",
                "identificativo": "LVLDAA85T50G702B",
                "anagrafica": "Ada Lovelace",
            },
        },
        */
        {
          headers: { Authorization: "Bearer qawj3o27wf0tlopgqznlhg00gf37pvki" },
        }
      )
      
    }
  }

  let apiService = new ApiService();
  export default apiService;